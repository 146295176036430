import React from 'react';
import Fade from 'react-reveal/Fade';

const ContactInfo = (props) => {
    const { contactBoxClass } = props;
    return (
        <div className={contactBoxClass ? contactBoxClass : 'ras-contact-address'}>
            <Fade left>
                <h2>GET IN TOUCH</h2>
                <p>We'd love to hear from you! Whether you have a question about our services, need assistance, or just want to provide feedback, we're here to help.</p>

                <h6 className="mb-0">Address:</h6>
                <p className="mb-10">8603 S Dixie Hwy. Suite 402., Pinecrest, FL 33156</p>
                <h6 className="mb-0">Email:</h6>
                <p className="mb-10">info@idevops7.com</p>
                <h6 className="mb-0">Phone:</h6>
                <p className="mb-10">(786) 656-3158</p>
            </Fade>
        </div>
    );

}

export default ContactInfo;