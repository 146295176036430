import React from 'react';
import Fade from 'react-reveal/Fade';
import SingleService from '../../components/Service/SingleService';

import serviceShape from '../../assets/images/eclips-shape-1.svg';

const Service = () => {

    return (
        <div className="ras-services ras-services-1 sec-spacer">
            <img src={serviceShape} alt="service1-bg" className="ras-services-1-bg"/>
            <div className="container">
                <Fade bottom>
                    <h2 className="mb-50 md-mb-30 text-center">Whether you're a startup looking to disrupt the market<br/> or
                        an established enterprise seeking to innovate, iDevOps7 is your trusted partner for all your
                        software development needs.</h2>
                </Fade>
                <div className="row">
                    <div className="col-lg-4">
                        <Fade bottom>
                            <SingleService
                                itemClass="ras-services-item fadeInLeft"
                                IconClass="ras-service-icon purple-bg-icon"
                                BtnClass="read-more bg-purple"
                                Icon='common/customSoftwareDevelopment.png'
                                Title="Custom Software Development"
                                Desc="From concept to deployment, we specialize in developing custom software solutions tailored to your specific business needs, leveraging methodologies such as Business Model Canvas and Lean Model Canvas."
                            />
                        </Fade>
                    </div>
                    <div className="col-lg-4">
                        <Fade bottom>
                            <SingleService
                                itemClass="ras-services-item fadeInLeft"
                                IconClass="ras-service-icon orange-bg-icon"
                                BtnClass="read-more bg-orange"
                                Icon='services/mobileApplication.png'
                                Title="Mobile Application"
                                Desc="We design and develop intuitive mobile applications for iOS and Android platforms, helping you reach your customers wherever they are enhancing your business's reach and engagement."
                            />
                        </Fade>
                    </div>
                    <div className="col-lg-4">
                        <Fade bottom>
                            <SingleService
                                itemClass="ras-services-item fadeInLeft"
                                IconClass="ras-service-icon skyblue-bg-icon"
                                BtnClass="read-more bg-sky-blue"
                                Icon='services/enterpriseSolution.png'
                                Title="Enterprise Solutions"
                                Desc="Streamline your business processes and enhance productivity with our scalable and secure enterprise "
                            />
                        </Fade>
                    </div>
                    <div className="col-lg-4">
                        <Fade bottom>
                            <SingleService
                                itemClass="ras-services-item fadeInLeft"
                                IconClass="ras-service-icon red-bg-icon"
                                BtnClass="read-more bg-red"
                                Icon='services/design.png'
                                Title="UI/UX Design"
                                Desc="Our talented designers create visually stunning and intuitive user interfaces that enhance the overall user experience."
                            />
                        </Fade>
                    </div>
                    <div className="col-lg-4">
                        <Fade bottom>
                            <SingleService
                                itemClass="ras-services-item fadeInLeft"
                                IconClass="ras-service-icon red-bg-icon"
                                BtnClass="read-more bg-red"
                                Icon='services/qualityAssurance.png'
                                Title="Quality Assurance"
                                Desc="We employ rigorous testing methodologies to ensure that your software solution is robust, reliable, and bug-free."
                            />
                        </Fade>
                    </div>
                    {/*<div className="col-lg-4">*/}
                    {/*    <Fade bottom>*/}
                    {/*        <SingleService*/}
                    {/*            itemClass="ras-services-item fadeInLeft"*/}
                    {/*            IconClass="ras-service-icon yellow-bg-icon"*/}
                    {/*            BtnClass="read-more bg-yellow"*/}
                    {/*            Icon='flaticon-user'*/}
                    {/*            Title="Social Marketing"*/}
                    {/*            Desc="According to elite we providing web Development*/}
                    {/*            services to our honorable clients for business."*/}
                    {/*        />*/}
                    {/*    </Fade>*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-4">*/}
                    {/*    <Fade bottom>*/}
                    {/*        <SingleService*/}
                    {/*            itemClass="ras-services-item fadeInLeft"*/}
                    {/*            IconClass="ras-service-icon orange-bg-icon"*/}
                    {/*            BtnClass="read-more bg-orange"*/}
                    {/*            Icon='flaticon-email'*/}
                    {/*            Title="Email Marketing"*/}
                    {/*            Desc="According to elite we providing web Development*/}
                    {/*            services to our honorable clients for business."*/}
                    {/*        />*/}
                    {/*    </Fade>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}

export default Service;