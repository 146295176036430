import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

import normalLogo from '../../assets/images/logos/idevops_logo.png';
import stickyLogo from '../../assets/images/logos/idevops_logo.png';
import Stack from '@mui/material/Stack';
const HeaderStyleTwo = (props) => {
    const {headerClass, parentMenu, headerNormalLogo, headerStickyLogo, mobileNormalLogo} = props;

    const [menuOpen, setMenuOpen] = useState(false)
    const [isVisible, setIsVisible] = useState(false);

    const [home, setHome] = useState(false)
    const [pages, setPages] = useState(false)

    const openMobileMenu = menu => {

        if (menu === 'home') {
            setHome(!home)
            setPages(false)
        } else if (menu === 'pages') {
            setHome(false)
            setPages(!pages)
        }
    };

    const location = useLocation();

    useEffect(() => {
        // Sticky is displayed after scrolling for 100 pixels
        const toggleVisibility = () => {
            if (window.pageYOffset > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <React.Fragment>
            <div className={headerClass ? headerClass : 'ras-header ras-header-2 ras-header-2-alt'}>
                <div className={isVisible ? 'menu-bar menu-sticky sticky' : 'menu-bar menu-sticky'}>
                    <div className="container">
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack direction="row" spacing={2} alignItems="center">
                                <div className="logo">
                                    {
                                        isVisible ?
                                            <Link to="/">
                                                <img src={headerStickyLogo ? headerStickyLogo : stickyLogo} alt=""/>
                                            </Link> :
                                            <Link to="/">
                                                <img src={headerNormalLogo ? headerNormalLogo : normalLogo} alt=""/>
                                            </Link>
                                    }
                                </div>
                                <div id="slogan">
									<span style={{
                                        color: "#1f78c1"
                                    }}>  We Make IT Happen!</span>
                                </div>
                            </Stack>

                            <Stack spacing={2} direction="row" alignItems="center">
                                <nav className="ras-main-menu">
                                    <ul className={menuOpen ? "nav-menu menu-open" : "nav-menu"}>
                                        <li className={location.pathname === "/" ? "active" : ""}>
                                            <Link to="/">Home</Link>
                                        </li>

                                        <li className={location.pathname === "/service" ? "active" : ""}>
                                            <Link to="/service">Services</Link>
                                        </li>
                                        <li className={location.pathname === '/about' ? 'active' : ''}>
                                            <Link to="/about">About</Link>
                                        </li>
                                        <li className={location.pathname === '/contact' ? 'active' : ''}>
                                            <Link to="/contact">Contact</Link>
                                        </li>
                                        <li><a href="tel:3059002390"><i class="icofont-iphone"></i> (305) 900-2390</a>
                                        </li>
                                        <li><Link to="/contact">Get Started</Link></li>
                                    </ul>
                                    <button class={menuOpen ? "mobile-menu-icon open" : "mobile-menu-icon"}
                                            aria-label="Main Menu Icon" onClick={() => {
                                        setMenuOpen(!menuOpen)
                                    }}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>
                                </nav>
                            </Stack>
                        </Stack>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default HeaderStyleTwo;