import React from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import {useAlert} from "../../hooks/useAlert";

export default function SnackAlert({type, text}) {
    const [open, setOpen] = React.useState(true);
    const {setAlert} = useAlert();
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert(null)
        setOpen(false);
    };
    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={type}
                    anchorOrigin={{vertical: "top", horizontal: "left"}}
                    variant="filled"
                    sx={{width: '100%'}}
                    TransitionComponent={<Slide direction="up" />}
                >
                    {text}
                </Alert>
            </Snackbar>
        </>
    )
}