import React from 'react';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import ContactMain from './ContactMain';
import ScrollToTop from '../../components/ScrollTop';
// Image
import Logo from '../../assets/images/logos/idevops_logo.png';
import HeaderStyleTwo from "../../components/Header/HeaderStyleTwo";
import {useAlert} from "../../hooks/useAlert";
import SnackAlert from "../../components/SnackAlerts/snack-alert";


const Contact = () => {
    const {alert} = useAlert()
    return (
        <React.Fragment>
            <HeaderStyleTwo contact/>
            <Breadcrumb pageTitle="Contact us"/>
            <ContactMain/>
            {/* scrolltop-start */}
            <ScrollToTop/>
            {/* scrolltop-end */}
            <Footer footerLogo={Logo}/>
            {alert ? <SnackAlert {...alert}/> : ""}
        </React.Fragment>
    );
}


export default Contact;

