import {Icon} from '@iconify/react';

import Box from '@mui/material/Box';


export default function Iconify({icon, width = 20, sx, ...other}) {
    return (
        <Box
            component={Icon}
            className="component-iconify"
            icon={icon}
            sx={{width, height: width, ...sx}}
            {...other}
        />
    )
}


