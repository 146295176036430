import Iconify from "../Iconify/Iconify";
import {IconButton} from "@mui/material";

export default function SocialMediaButton({icon}) {
    const icons = {
        facebook: "lucide:facebook",
        twitter: "ri:twitter-x-line",
        x: "ri:twitter-x-line",
        instagram: "iconoir:instagram",
        youtube: "ri:youtube-fill"

    }
    return (
        <>
            <IconButton sx={{border: "1px solid white"}}>
                <Iconify sx={{
                    color: "white"
                }} width="20px" icon={icons[icon]}/>
            </IconButton>
        </>
    )
}