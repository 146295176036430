import React, {useState} from 'react';
import Fade from 'react-reveal/Fade';
import {routes} from "../../routes";
import SnackAlert from "../../components/SnackAlerts/snack-alert";
import {useAlert} from "../../hooks/useAlert";
import {contactFormAlerts} from "../../components/SnackAlerts/data";
import axios from 'axios'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function ContactForm() {
    const {setAlert} = useAlert();
    const [formData, setFormData] = useState({name: "", email: "", phone: "", message: ""});
    const [phone, setPhone] = useState("")


    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormData({
            ...formData,
            phone: phone
        })
        const apiUrl = routes.emailFunction;
        const queryString = Object.keys(formData).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`).join('&');
        const response = await axios.post(`${apiUrl}?${queryString}`,
            {
                headers: {
                    'Access-Control-Allow-Credentials': 'true',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                },
            })

        setAlert({type: contactFormAlerts.success.type, text: contactFormAlerts.success.text});
    }

    return (
        <div className="ras-contact-form">
            <Fade right>
                <h2>Book an Appointments</h2>
                <form id="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label for="name" className="sr-only">Name</label>
                        <input required type="text" name="name" className="form-control" onChange={handleChange}
                               value={formData.name} id="name" placeholder="Stephine Smith"/>
                    </div>

                    <div className="form-group">
                        <label for="email" className="sr-only">Email</label>
                        <input required type="email" name="email" className="form-control" onChange={handleChange}
                               value={formData.email} id="email" placeholder="name@example.com"/>
                    </div>

                    <div className="form-group">
                        <label for="phone" className="sr-only">Phone</label>
                        <PhoneInput
                            inputProps={{
                                name: 'phone',
                                required: true,
                            }}
                            country={'us'}
                            value={phone}
                            disableDropdown
                            enableAreaCodes={['us']}
                            onlyCountries={['us']}
                            inputStyle={{
                                width: "100%"
                            }}
                            onChange={newPhone => setPhone(newPhone)}
                        />

                    </div>
                    <div className="form-group">
                        <label for="message" className="sr-only">Message</label>
                        <textarea required className="form-control" id="message" rows="4" name="message"
                                  onChange={handleChange} value={formData.message} placeholder="Message"></textarea>
                    </div>

                    <div className="form-group mb-0">
                        <button type="submit" className="btn btn-primary btn-large">Send Message</button>
                    </div>
                    {alert.type ?
                        <SnackAlert {...alert}/>
                        :
                        ""}
                </form>
            </Fade>
        </div>
    );

}
