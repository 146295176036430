import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ServiceMain from './ServiceMain';

// Image
import Logo from '../../assets/images/logos/idevops_logo.png';
import HeaderStyleTwo from "../../components/Header/HeaderStyleTwo";

const Service = () => {

    return (
        <React.Fragment>
            <HeaderStyleTwo/>

            {/* Service Main */}
            <ServiceMain />
            {/* Service Main */}

            <Footer
                footerLogo={Logo}
            />
        </React.Fragment>
    );
}

export default Service;