import React from 'react';
import HomeMain from './HomeMain';
import HeaderStyleTwo from '../../components/Header/HeaderStyleTwo';
import Footer from '../../components/Footer';

import footerLogo from '../../assets/images/logos/idevops_logo.png';

class HomePage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <HeaderStyleTwo/>
                <HomeMain/>
                <Footer
                    footerLogo={footerLogo}
                />
            </React.Fragment>
        );
    }
}

export default HomePage;
