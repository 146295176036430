import React, { createContext, useState, useContext } from 'react';

// Create a context for the alert
const AlertContext = createContext(null);

// Provider component that manages the alert state
export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState(null);

    return (
        <AlertContext.Provider value={{ alert, setAlert }}>
            {children}
        </AlertContext.Provider>
    );
};

// Custom hook to use the Alert context
export const useAlert = () => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used within an AlertProvider');
    }
    return context;
};