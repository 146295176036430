import React from 'react';
import Fade from 'react-reveal/Fade';

import SingleService from '../../components/Service/SingleService';

import serviceShape from '../../assets/images/eclips-shape-1.svg';

const Service = () => {

    return (
        <section id="service-section" className="ras-services ras-services-1 ras-service-1-spacer-bottom" >
            <img src={serviceShape} alt="service1-bg" className="ras-services-1-bg" />
            <div className="container">
                <Fade bottom>
                    <h2 className="mb-50 text-center">We specialize in applying innovative methodologies such as Business Model Canvas and Lean Model Canvas to the process of creating custom-made software solutions for our clients.</h2>
                </Fade>
                <Fade left cascade>
                    <div className="row">
                        <div className="col-lg-4">
                            <SingleService
                                itemClass="ras-services-item"
                                Icon='common/customSoftwareDevelopment.png'
                                Title="Custom Software Development"
                                Desc="We take a personalized approach to every project, leveraging methodologies like Business Model Canvas and Lean Model Canvas to ensure that our solutions are not only technically sound but also aligned with your business objectives. We create tailor-made software solutions designed to meet the unique needs of your business, ensuring optimal performance and results."
                            />
                        </div>
                        <div className="col-lg-4">
                            <SingleService
                                itemClass="ras-services-item"
                                Icon='home/businessModelCanvas.png'
                                Title="Business Model Canvas"
                                Desc="A strategic management tool that allows organizations to visualize, analyze, and innovate upon their business model. It provides a holistic view of key elements that make up a business, enabling stakeholders to understand how value proposition is created, delivered, and captured."
                            />
                        </div>
                        <div className="col-lg-4">
                            <SingleService
                                itemClass="ras-services-item"
                                Icon='home/leanModelCanvas.png'
                                Title="Lean Model Canvas"
                                Desc="A strategic management tool inspired by the lean startup methodology. It provides a concise framework for entrepreneurs and organizations to define, test, and iterate upon their business model hypotheses quickly and efficiently."
                            />
                        </div>
                    </div>
                </Fade>
            </div>
        </section>
    );
}

export default Service;