import React from 'react';
import {Link} from 'react-router-dom';
import Slider from "react-slick";

import diamondShape from "../../assets/images/diamond-shape.svg";
import curlyShape from "../../assets/images/curly-shape.svg";
import bannerDots1 from "../../assets/images/banner-dots-1.svg";
import bannerDots2 from "../../assets/images/banner-dots-2.svg";
import codeimg from "../../assets/images/code.svg"

const HomeSlider = () => {
    return (
        <React.Fragment>
            <div className="ras-banner ras-banner-1" >
                <div className="slider-active text-center">
                    <Slider>
                        <div className="ras-slide" >
                            <div className="container">
                                <div className="ras-slide-content">
                                    {/*<h3 className="ras-slide-sub-heading text-white">iDevOps7</h3>*/}
                                    <h2 className="ras-slide-heading text-white">Let's transform your ideas into
                                        reality!</h2>
                                    <p className="ras-slide-desc text-white mb-50 subtitle">
                                        At iDevOps7, we go beyond traditional software development approaches.
                                    </p>
                                    <Link to="/contact" className="btn btn-primary btn-large hero-btn">Start Your
                                        Journey<i className="icofont-long-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
                {/*<div className="ras-banner-shapes">*/}
                {/*    <img src={diamondShape} alt="banner-diamond-shape" className="banner-diamond-shape"/>*/}
                {/*    <img src={codeimg} alt="banner-curve-shape" className="banner-curly-shape"/>*/}
                {/*    <img src={bannerDots1} alt="banner-dots-1" className="banner-square-dots-1"/>*/}
                {/*    <img src={bannerDots2} alt="banner-dots-2" className="banner-square-dots-2"/>*/}
                {/*</div>*/}
            </div>
        </React.Fragment>
    );
}

export default HomeSlider;