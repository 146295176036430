import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AboutMain from './AboutMain';

// Image
import Logo from '../../assets/images/logos/idevops_logo.png';
import HeaderStyleTwo from "../../components/Header/HeaderStyleTwo";

const About = () => {

    return (
        <React.Fragment>
            <HeaderStyleTwo/>

            {/* About Main */}
            <AboutMain />
            {/* About Main */}

            <Footer
                footerLogo={Logo}
            />
        </React.Fragment>
    );
}

export default About;