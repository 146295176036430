import React from 'react';
import {Link} from 'react-router-dom';
import Fade from 'react-reveal/Fade';

// Categories Image
import featureImg from '../../assets/images/features/featureImg.jpg';

const Feature = () => {

    return (
        <div className="ras-features ras-features-1 sec-spacer-bottom-120">
            <div className="container relative">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <Fade left>
                            <div className="ras-features-image">
                                <img src={featureImg} alt="our-features"/>
                                <a className="support-mail" href="mailto:info@idevops7.com">info@idevops7.com</a>
                                <div className="features-color-dots"></div>
                                <div className="features-white-dots"></div>
                            </div>
                        </Fade>
                    </div>
                    <div className="col-lg-6">
                        <div className="ras-features-details">
                            <Fade right>
                                <h2>A software company of talented and innovative professionals
                                </h2>
                                <p>
                                    Your satisfaction is our top priority. We work closely with you at every stage of
                                    the project, from initial concept to final delivery, to ensure that the end result
                                    not only meets but exceeds your expectations.
                                </p>
                            </Fade>
                            <Fade bottom cascade>
                                <div className="row features-items-wrapper">
                                    <div className="col-sm-6 col-6">
                                        <div className="features-item">
                                            <div className="features-icon">
                                                <img alt="icon" src={`/img/icons/home/cuttingEdgeTechnology.png`}/>
                                            </div>
                                            <h5>Cutting-Edge Technology</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6">
                                        <div className="features-item">
                                            <div className="features-icon">
                                                <img alt="icon" src={`/img/icons/home/customerCentricApproach.png`}/>
                                            </div>
                                            <h5>Customer-Centric Approach</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6">
                                        <div className="features-item">
                                            <div className="features-icon">
                                                <img alt="icon" src={`/img/icons/home/riskMitigation.png`}/>
                                            </div>
                                            <h5>Risk Mitigation</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6">
                                        <div className="features-item">
                                            <div className="features-icon">
                                                <img alt="icon" src={`/img/icons/home/focusedOnValidation.png`}/>
                                            </div>
                                            <h5>Focused on Validation</h5>
                                        </div>
                                    </div>

                                </div>
                            </Fade>
                            <Fade bottom>
                                <div className="features-btn text-left mt-4">
                                    <Link to="/contact" className="btn btn-large btn-primary">Get Started Tour</Link>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ras-blur-background"></div>
        </div>
    );
}

export default Feature;